@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#top {
  .p-mv {
    display: flex;
    justify-content: space-between;
    gap: 5.7rem;
    background: url(../../images/top_mv_bg.svg) no-repeat;
    background-size: cover;
    padding-top: 8.5rem;
    @include media(sm) {
      flex-direction: column;
      background: url(../../images/top_mv_bg_sp.svg) no-repeat;
    background-size: cover;
    background-position: center top 0;
    }
    &__textArea {
      padding-top: 6.5rem;
      padding-left: 15rem;
      @include media(sm) {
        padding-top: 1rem;
        padding-left: 2.5rem;
      }
      .tit {
        @include fzr(42,72,900);
        margin-bottom: 3.4rem;
        @include media(sm) {
          @include fzr(30,50,900);
          margin-bottom: 2.3rem;
        }
      }
      .text {
        @include fzr(16,32,500);
        @include media(sm) {
          @include fzr(14,28,500);
        }
      }
      .btnArea {
        display: flex;
        gap: .7rem;
        margin-top: 5rem;
        @include media(sm) {
          margin-top: 2rem;
          gap: 1.8rem;
          justify-content: center;
        }
      }
    }
    &__imageArea {
      img {
        width: 73.8rem;
        @include media(sm) {
          width: 100%;
        }
      }
    }
  }
  .p-sec01 {
    position: relative;
    z-index: 2;
    padding: 10.4rem 0 12.6rem;
    background: url(../../images/top_sec01_bg.png) no-repeat;
    background-size: 128rem;
    margin-top: -1rem;
    @include media(sm) {
      background-size: 37.5rem;
      padding: 15.7rem 0 8rem;
      overflow: hidden;
    }
    &-flow {
      margin-top: 6.7rem;
      display: flex;
      gap: 2.8rem;
      @include media(sm) {
        flex-direction: column;
        gap: 5.7rem;
      }
      &__item {
        position: relative;
        + .p-sec01-flow__item {
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -2rem;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-top: 1.1rem solid transparent;
            border-bottom: 1.1rem solid transparent;
            border-left: 1.2rem solid #777777;
            border-right: 0;
            @include media(sm) {
              top: -2.8rem;
              left: 50%;
              transform: rotate(90deg) translateX(-50%);
            }
          }
        }
        .itemTit {
          position: relative;
          z-index: 2;
          @include fzr(18,41,900);
          text-align: center;
          @include media(sm) {
            margin-bottom: 2rem;
            @include fzr(16,41,900);
          }
          &::after {
            position: absolute;
            z-index: -1;
            bottom: .6rem;
            left: 50%;
            display: block;
            content: '';
            background-color: #FFEC61;
            width: 8.8rem;
            height: .9rem;
            transform: translateX(-50%) rotate(2deg);
          }
        }
        .contents {
          @include media(sm) {
            display: flex;
            gap: 1.4rem;
          }
          .imageArea {
            flex-shrink: 0;
            img {
              width: 22.4rem;
              @include media(sm) {
                width: 11.7rem;
              }
            }
          }
          .textArea {
            margin-top: 1.7rem;
            @include media(sm) {
              margin-top: 0;
            }
            .contentsTit {
              text-align: center;
              @include fzr(20,41,900);
              margin-bottom: 1.2rem;
              @include media(sm) {
                text-align: left;
                @include fzr(18,20,900);
                margin-bottom: 1rem;
              }
            }
            .text {
              @include fzr(16,25,500);
              @include media(sm) {
                @include fzr(14,23,500);
              }
            }
          }
        }
      }
    }
  }
  .p-sec02 {
    padding: 9.5rem 0;
    background-color: #fff;
    @include media(sm) {
      padding: 8rem 0;
    }
    &__tit {
      text-align: center;
      @include fzr(28,51,900);
      @include media(sm) {
        @include fzr(19,40,900);
        margin-bottom: 4rem;
      }
    }
    &-box {
      display: flex;
      gap: 2.6rem;
      margin-top: 4.2rem;
      @include media(sm) {
        flex-direction: column;
        gap: 1.8rem;
      }
      
      &__left {
        .itemLink {
          img {
            width: 30.8rem;
            @include media(sm) {
              width: 100%;
            }
          }
        }
      }
      &__right {
        .upper {
          display: flex;
          gap: 2.6rem;
          .itemLink {
            img {
              width: 30.8rem;
            }
          }
        }
        .lower {
          display: flex;
          gap: 2.6rem;
          margin-top: 2.6rem;
          @include media(sm) {
            gap: 1.8rem ;
            margin-top: 1.8rem;
          }
          .itemLink {
            img {
              width: 19.5rem;
            }
          }
        }
      }
    }
  }
  .p-sec03 {
    background: url(../../images/top_sec03_bg@2x.jpg) no-repeat;
    background-size: cover;
    padding: 32.6rem 0 7.8rem;
    @include media(sm) {
      background: url(../../images/top_sec03_bg_sp@2x.jpg) no-repeat;
    background-size: cover;
    padding: 15.7rem 0 6rem;
    }
    &-contents {
      &__tit {
        @include fzr(32,51,900);
        margin-bottom: 2.5rem;
        @include media(sm) {
          @include fzr(24,42,900);
        }
      }
      &__text {
        @include fzr(16,25,500);
        @include media(sm) {
          @include fzr(14,22,500);
        }
      }
      &__btnArea {
        margin-top: 4rem;
        .c-btnA {
          width: 21rem;
          &::before {
            width: 21rem;
          }
        }
      }
    }
  }
  .p-sec04 {
    position: relative;
    padding: 9rem 0 22.2rem;
    background: url(../../images/top_sec04_bg.svg) no-repeat;
    background-position: center bottom;
    background-size: 128rem;
    @include media(sm) {
      padding: 6rem 0 8rem;
    }
    &__bg {
      position: absolute;
      bottom: -3rem;
      right: 8.5rem;
      img {
        width: 5.6rem;
      }
    }
    &-contents {
      margin-top: 18.2rem;
      @include media(sm) {
        margin-top: 9.2rem;
      }
      &__box {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        column-gap: 2.8rem;
        row-gap: 5.6rem;
        margin-top: 5.6rem;
        @include media(sm) {
          grid-template-columns: repeat(2,1fr);
          column-gap: 1.8rem;
          row-gap: 4rem;
        }
      }
    }
  }
}