@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#service {
  .p-mv {
    margin-top: 11.3rem;
    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      content: "";
      background: url(../../images/search_upper_bg.png) no-repeat;
      background-size: 128rem;
      width: 100%;
      height: 78.5rem;
      @include media(sm) {
        background: url(../../images/search_upper_bg_sp.png) no-repeat;
        background-size: 37.5rem;
        width: 100%;
        height: 29.6rem;
      }
    }

    &__imageArea {
      position: relative;
      .tit {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include fzr(32, 48, 900);
        @include media(sm) {
          @include fzr(24, 40, 900);
          width: 100%;
          top: 7.6rem;
          left: 0;
          transform: none;
          text-align: center;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .p-sec01 {
    position: relative;
    padding: 14.3rem 0 20rem;
    overflow: hidden;
    &::after {
      position: absolute;
      z-index: -1;
      bottom: -2rem;
      left: 0;
      right: 0;
      content: "";
      background: url(../../images/search_rower_bg.png) no-repeat;
      background-size: 128rem;
      width: 100%;
      height: 16.3rem;
      @include media(sm) {
        background: url(../../images/search_rower_bg_sp.png) no-repeat;
        background-size: 37.5rem;
        width: 37.5;
        height: 9.4rem;
      }
    }
    &-contents {
      + .p-sec01-contents {
        margin-top: 14.7rem;
        @include media(sm) {
          margin-top: 10.3rem;
        }
      }
      &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.8rem;
        margin-top: 6.4rem;
        @include media(sm) {
          margin-top: 1.7rem;
          flex-direction: column;
          gap: 2.3rem;
        }
        &__imageArea {
          flex-shrink: 0;
          img {
            width: 47.6rem;
            @include media(sm) {
              width: 100%;
            }
          }
        }
        &__textArea {
          .tit {
            @include fzr(24, 48, 900);
            margin-bottom: 2rem;
            @include media(sm) {
              @include fzr(18, 30, 900);
            }
          }
          .text {
            @include fzr(16, 30, 500);
            @include media(sm) {
              @include fzr(14, 24, 500);
            }
          }
        }
      }
      &__flow {
        margin-top: 6.7rem;
        display: flex;
        gap: 2.8rem;
        @include media(sm) {
          flex-direction: column;
          gap: 5.7rem;
        }
        &__item {
          position: relative;
          width: 30.8rem;
          @include media(sm) {
            width: auto;
          }
          + .p-sec01-contents__flow__item {
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: -2rem;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-top: 1.1rem solid transparent;
              border-bottom: 1.1rem solid transparent;
              border-left: 1.2rem solid #777777;
              border-right: 0;
              @include media(sm) {
                top: -2.8rem;
                left: 50%;
                transform: rotate(90deg) translateX(-50%);
              }
            }
          }
          .itemTit {
            position: relative;
            z-index: 2;
            @include fzr(18, 41, 900);
            text-align: center;
            @include media(sm) {
              margin-bottom: 2rem;
              @include fzr(16, 41, 900);
            }
            &::after {
              position: absolute;
              z-index: -1;
              bottom: 0.6rem;
              left: 50%;
              display: block;
              content: "";
              background-color: #ffec61;
              width: 8.8rem;
              height: 0.9rem;
              transform: translateX(-50%) rotate(2deg);
            }
          }
          .contents {
            @include media(sm) {
              display: flex;
              gap: 1.4rem;
            }
            .imageArea {
              flex-shrink: 0;
              img {
                width: 30.8rem;
                @include media(sm) {
                  width: 11.7rem;
                }
              }
            }
            .textArea {
              margin-top: 1.7rem;
              @include media(sm) {
                margin-top: 0;
              }
              .contentsTit {
                text-align: center;
                @include fzr(20, 41, 900);
                margin-bottom: 1.2rem;
                @include media(sm) {
                  text-align: left;
                  @include fzr(18, 20, 900);
                  margin-bottom: 1rem;
                }
              }
              .text {
                @include fzr(16, 25, 500);
                @include media(sm) {
                  @include fzr(14, 23, 500);
                }
              }
            }
          }
        }
      }
      &__btnArea {
        display: flex;
        justify-content: center;
        margin-top: 5.1rem;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 47.6rem;
          height: 9.1rem;
          background-color: #920303;
          border-radius: 2.7rem;
          color: #fff;
          @include fzr(20, 26, 900);
        }
      }
      &__qa {
        margin-top: 5rem;
        @include media(sm) {
          margin-top: 3.6rem;
        }
        .qaItem {
          + .qaItem {
            margin-top: 3.1rem;
            @include media(sm) {
              margin-top: 3.6rem;
            }
          }
          .itemTit {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            @include media(sm) {
              align-items: flex-start;
            }
            .en {
              color: #195b6e;
              @include fzr(32, 48, 900);
              @include media(sm) {
                @include fzr(28, 48, 900);
              }
            }
            .text {
              @include fzr(20, 48, 900);
              @include media(sm) {
                @include fzr(14, 23, 900);
                padding-top: 1rem;
              }
            }
          }
          .itemText {
            margin-top: 3.1rem;
            display: flex;
            
            gap: 0.6rem;
            @include media(sm) {
              align-items: flex-start;
              margin-top: 1.1rem;
            }
            .en {
              color: #c6572e;
              @include fzr(32, 48, 900);
              @include media(sm) {
                @include fzr(28, 48, 900);
              }
            }
            .text {
              @include fzr(16, 26, 500);
              @include media(sm) {
                @include fzr(14, 23, 500);
                padding-top: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
