@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-cardA {
  position: relative;
  &__cat {
    position: absolute;
    z-index: 2;
    top: -1.8rem;
    left: 1rem;
    @include fzr(12, 26, 500);
    color: #fff;
    padding: 0.5rem 1.2rem;
    background-color: #c84658;
    border-radius: 1.2rem;
  }
  &__imageArea {
    position: relative;
    border: 1px solid #c84658;
    width: 21.4rem;
    height: 21.4rem;
    @include media(sm) {
      width: 14.8rem;
      height: 14.8rem;
    }
    .dlArea {
      position: absolute;
      right: -1rem;
      bottom: -1rem;

      display: flex;
      align-items: center;
      background-color: #c84658;
      padding: 0.9rem 1.6rem;
      border-top-left-radius: 1rem;
      gap: 0.4rem;
      .text {
        color: #fff;
        @include fzr(12, 26, 500);
      }
    }
    &::before {
      position: absolute;
      top: -1px;
      right: -1.1rem;
      content: "";
      width: 1.1rem;
      height: 22.4rem;
      display: block;
      background-color: #c84658;
      clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 96%);
      @include media(sm) {
        right: -0.7rem;
        width: 0.7rem;
        height: 15.4rem;
      }
    }
    &::after {
      position: absolute;
      left: -1px;
      bottom: -1.1rem;
      content: "";
      height: 1.1rem;
      width: 22.4rem;
      display: block;
      background-color: #c84658;
      clip-path: polygon(0 0, 96% 0, 100% 100%, 4% 100%);
      @include media(sm) {
        bottom: -0.7rem;
        height: 0.7rem;
        width: 15.4rem;
      }
    }
  }
  &__tit {
    margin-top: 2.2rem;
    @include fzr(20, 25, 900);
    text-align: center;
    @include media(sm) {
      margin-top: 1rem;
      @include fzr(16, 25, 900);
    }
  }
  &--cat02 {
    .c-cardA__cat {
      background-color: #63b166;
    }
    .c-cardA__imageArea {
      border-color: #63b166;
      .dlArea {
        background-color: #63b166;
      }
      &::before {
        background-color: #63b166;
      }
      &::after {
        background-color: #63b166;
      }
    }
    .c-cardA__infoArea {
      .nameArea {
        color: #63b166;
      }
    }
  }
  &--cat03 {
    .c-cardA__cat {
      background-color: #516ba7;
    }
    .c-cardA__imageArea {
      border-color: #516ba7;
      .dlArea {
        background-color: #516ba7;
      }
      &::before {
        background-color: #516ba7;
      }
      &::after {
        background-color: #516ba7;
      }
    }
    .c-cardA__infoArea {
      .nameArea {
        color: #516ba7;
      }
    }
  }
  &--cat04 {
    .c-cardA__cat {
      background-color: #d86600;
    }
    .c-cardA__imageArea {
      border-color: #d86600;
      .dlArea {
        background-color: #d86600;
      }
      &::before {
        background-color: #d86600;
      }
      &::after {
        background-color: #d86600;
      }
    }
    .c-cardA__infoArea {
      .nameArea {
        color: #d86600;
      }
    }
  }
  &--cat05 {
    .c-cardA__cat {
      background-color: #d46bcd;
    }
    .c-cardA__imageArea {
      border-color: #d46bcd;
      .dlArea {
        background-color: #d46bcd;
      }
      &::before {
        background-color: #d46bcd;
      }
      &::after {
        background-color: #d46bcd;
      }
    }
    .c-cardA__infoArea {
      .nameArea {
        color: #d46bcd;
      }
    }
  }
  &--cat06 {
    .c-cardA__cat {
      background-color: #5ba1c5;
    }
    .c-cardA__imageArea {
      border-color: #5ba1c5;
      .dlArea {
        background-color: #5ba1c5;
      }
      &::before {
        background-color: #5ba1c5;
      }
      &::after {
        background-color: #5ba1c5;
      }
    }
    .c-cardA__infoArea {
      .nameArea {
        color: #5ba1c5;
      }
    }
  }
  &--search {
    display: flex;
    height: 100%;
    flex-direction: column;
    // justify-content: space-between;
    .c-cardA__tit {
      padding-bottom: 1rem;
    }
    .c-cardA__infoArea {
      border-top: 1px solid #707070;
      padding-top: 1rem;
      .nameArea {
        display: flex;
        justify-content: center;
        color: #c84658;
        @include fzr(14, 25, 900);
      }
      .btnArea {
        margin-top: 1.6rem;
        .c-btnA {
          width: 100%;
          &::before {
            width: 100%;
          }
        }
      }
      .tagArea {
        margin-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.4rem;
        .tag {
          padding: 1rem 1.5rem;
          background-color: #dedede;
          @include fzr(12, 26, 500);
          border-radius: 1rem;
          @include media(sm) {
            padding: 0.8rem 1.2rem;
          }
        }
      }
    }
    .c-cardA__upper {
      display: flex;
      gap: 2.8rem;
      .c-cardA__imageAreaWrap {
        position: relative;
        .c-cardA__cat {
          @include media(sm) {
            padding: 0.6rem 1rem;
            @include fzr(10, 20, 500);
            border-radius: 1.2rem;
            left: 0.5rem;
          }
        }
      }
      .c-cardA__infoAreaWrap {
        width: 50%;
        .c-cardA__tit {
          margin-top: 1.7rem;
          @include media(sm) {
            margin-top: 0.9rem;
          }
        }
        .btnArea {
          .dlArea {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #c84658;
            padding: 1rem 0 0.9rem;
            border-radius: 1rem;
            &--cat02 {
              background-color: #63b166;
            }
            &--cat03 {
              background-color: #516ba7;
            }
            &--cat04 {
              background-color: #d86600;
            }
            &--cat05 {
              background-color: #d46bcd;
            }
            &--cat06 {
              background-color: #5ba1c5;
            }
            .text {
              color: #fff;
              @include media(sm) {
                @include fzr(12, 26, 500);
              }
            }
          }
        }
      }
    }
    .c-cardA__lower {
      display: flex;
      gap: 0.8rem;
      margin-top: 2.8rem;
      @include media(sm) {
        margin-top: 2rem;
      }
      .c-btnA {
        width: 23.4rem;
        height: 5rem;
        &::before {
          width: 23.4rem;
          height: 5rem;
          @include media(sm) {
            width: 16rem;
            height: 4.4rem;
          }
        }
        @include media(sm) {
          width: 16rem;
          height: 4.4rem;
          @include fzr(14,20,700);
        }
      }
    }
  }
}
