@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.l-ftr {
  background-color: #333333;
  padding: 8rem 0 4.5rem;
  @include media(sm) {
    padding: 6.8rem 0 5.1rem;
  }
  &-navArea {
    &__nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      @include media(sm) {
        gap: 1.4rem;
        flex-wrap: wrap;
      }
      .navItem {
        @include fzr(14,20,500);
        color: #fff;
        @include media(sm) {
          width: 15.4rem;
          text-align: center;
        }
      }
    }
  }
  &-copyArea {
    margin-top: 4.3rem;
    text-align: center;
    @include media(sm) {
      margin-top: 5.8rem;
    }
    &__copy {
      @include fzr(12,17,500);
      color: #fff;
      @include media(sm) {
        @include fzr(10,15,500);
      }
    }
  }
}