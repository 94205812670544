@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-search {
  position: relative;
  padding: 20rem 0 4.2rem;
  overflow: hidden;
  @include media(sm) {
    padding: 6rem 2.5rem 0;

  }
  .c-inner {
    &--no {
      @include media(sm) {
        width: 100%;
      }
    }
  }
  // &::before {
  //   position: absolute;
  //   top: 0;
  //   left: -29rem;
  //   content: "";
  //   background: url(../../images/top_sec01_bg01.svg) no-repeat;
  //   background-size: 60.2rem;
  //   width: 60.2rem;
  //   height: 15.2rem;
  //   @include media(sm) {
  //     background: url(../../images/top_sec01_bg01_sp.svg) no-repeat;
  //     background-size: 49.4rem;
  //     width: 49.4rem;
  //     height: 9.3rem;
  //     top: 1rem;
  //     left: -36rem;
  //   }
  // }
  &::after {
    position: absolute;
    bottom: 0;
    right: -33rem;
    content: "";
    background: url(../../images/top_sec01_bg02.svg) no-repeat;
    background-size: 59.6rem;
    width: 59.6rem;
    height: 15.7rem;
    @include media(sm) {
      background: url(../../images/top_sec01_bg02_sp.svg) no-repeat;
      background-size: 49.4rem;
      width: 49.4rem;
      height: 9.3rem;
      right: -37rem;
    }
  }
  &__text {
    position: relative;
    z-index: 3;
    @include fzr(16, 26, 700);
    color: #fff;
    text-align: center;
    @include media(sm) {
      @include fzr(14,23,700);
      text-align: left;
      padding: 0 1.5rem;
    }
  }
  &-searchArea {
    width: 64.4rem;
    margin: 3.4rem auto 0;

    @include media(sm) {
      width: 100%;
    }
    &__box {
      display: flex;
      background-color: #fff;
      border-radius: 1.5rem;
      align-items: center;
      padding: 1rem 2.9rem;
      border-radius: 15px;
box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);

      @include media(sm) {
        padding: 1rem 2rem;
      }
      .inputArea {
        input {
          @include fzr(16, 26, 700);
          width: 41rem;
          @include media(sm) {
            width: 17.5rem;
          }
        }
      }
      .selectArea {
        position: relative;
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
        height: 100%;
        padding: 2.2rem 0 2.2rem 1.4rem;
        @include media(sm) {
          padding: .9rem 0 .9rem 1.4rem;
        }
        select {
          @include fzr(16, 26, 700);
          padding-right: 3rem;
          @include  media(sm) {
            @include fzr(12, 26, 700);
            padding-right: 0;
            padding-bottom: 1rem;
          }
        }
        .icon {
          position: absolute;
          right: 1.4rem;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          @include media(sm) {
            right: 50%;
            transform: translateX(50%);
            bottom: 1rem;
            top: auto;
          }
        }
      }
      .btnArea {
        padding-left: 2rem;
        flex-shrink: 0;
        @include media(sm) {
          padding-left: 1.4rem;
        }
        img {
          width: 2.8rem;
        }
      }
    }
    &__tagArea {
      margin-top: 1.4rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1.4rem;
      .tag {
        background-color: #DEDEDE;
        @include fzr(14, 26, 700);
        padding: 0.9rem 2.3rem;
        border-radius: 1rem;
      }
    }
  }
}