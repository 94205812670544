@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#search_detail {
  .p-sec01 {
    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      content: "";
      background: url(../../images/search_upper_bg.png) no-repeat;
      background-size: 128rem;
      width: 100%;
      height: 78.5rem;
      @include media(sm) {
        background: url(../../images/search_upper_bg_sp.png) no-repeat;
        background-size: 37.5rem;
        width: 100%;
        height: 29.6rem;
      }
    }

    &__contents {
      position: relative;
      padding: 16.2rem 0 20.9rem;
      position: relative;
      z-index: 3;
      @include media(sm) {
        padding: 5.6rem 0 16rem;
      }
      &::after {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        background: url(../../images/search_rower_bg.png) no-repeat;
        background-size: 128rem;
        width: 100%;
        height: 16.3rem;
        @include media(sm) {
          background: url(../../images/search_rower_bg_sp.png) no-repeat;
          background-size: 37.5rem;
          width: 37.5;
          height: 9.4rem;
        }
      }
    }
    &-detailArea {
      display: flex;
      gap: 3.8rem;
      @include media(sm) {
        flex-direction: column;
      }
      &__imageArea {
        position: relative;
        width: 54.9rem;
        background-color: #c84658;
        @include media(sm) {
          width: 100%;
        }
        &::before {
          position: absolute;
          top: -1px;
          right: -1.1rem;
          content: "";
          width: 1.1rem;
          height: calc(100% + 1.3rem);
          display: block;
          background-color: #c84658;
          clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 98%);
          @include media(sm) {
          }
        }
        &::after {
          position: absolute;
          left: -1px;
          bottom: -1.1rem;
          content: "";
          height: 1.1rem;
          width: calc(100% + 1.3rem);
          display: block;
          background-color: #c84658;
          clip-path: polygon(0 0, 98% 0, 100% 100%, 2% 100%);
          @include media(sm) {
          }
        }
        .mainImg {
          width: 100%;
          border: 1px solid #c84658;
          height: 38.2rem;
          overflow: hidden;
          @include media(sm) {
            width: 31.4rem;
            height: 21.4rem;
          }
        }
       
        .sliderArea {
          border: 1px solid #c84658;
          margin-top: 0.9rem;
          background-color: #fff;
          padding: 2.1rem 4.6rem 4.4rem;
          position: relative;
          @include media(sm) {
            margin-top: 1.3rem;
            padding: 2rem 1rem 4.2rem;
          }

          .swiper {
            .swiper-wrapper {
              .swiper-slide {
                // margin: 0 1.2rem;
                .sliderItem {
                  width: 13.9rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  @include media(sm) {
                    width: 9rem;
                  }
                  .imageArea {
                    width: 13.9rem;
                    height: 11.1rem;
                    @include media(sm) {
                      width: 9rem;
                      height: 7.2rem;
                    }
                  }
                  .tit {
                    margin-top: 0.9rem;
                    @include fzr(14, 26, 700);
                    text-align: center;
                    @include media(sm) {
                      margin-top: 1.3rem;
                      @include fzr(12, 26, 700);
                    }
                  }
                }
              }
            }
          }
          .swiper-button-next,
          .swiper-button-prev {
            width: 1rem;
            height: 1.7rem;
            @include media(sm) {
            }
            &::after {
              content: "";
              background: url(../../images/icon_swiperArrow.svg) no-repeat;
              background-size: 1rem;
              width: 1rem;
              height: 1.7rem;
              @include media(sm) {
              }
            }
          }
          .swiper-button-next {
            right: 1.8rem;

            &::after {
              transform: scale(-1, 1);
            }
          }
          .swiper-button-prev {
            left: 1.8rem;
          }
          .swiper-pagination {
            bottom: 2.1rem;
            .swiper-pagination-bullet {
              background-color: #fff;
              border: 1px solid #000;
              opacity: 1;
              width: 0.8rem;
              height: 0.8rem;
              &-active {
                background-color: #000;
              }
            }
          }
        }
      }
      &__textArea {
        padding-top: 2.2rem;
        @include media(sm) {
          padding-top: 0;
        }
        .infoArea {
          .tit {
            @include fzr(24, 26, 700);
            margin-bottom: 3rem;
            @include media(sm) {
              @include fzr(20, 26, 700);
              margin-bottom: 2.5rem;
            }
          }
          .infoList {
            .listItem {
              @include fzr(16, 26, 700);
              display: flex;
              gap: 1.5rem;
              @include media(sm) {
                @include fzr(14, 26, 700);
              }
              + .listItem {
                margin-top: 1.5rem;
                @include media(sm) {
                  margin-top: 1rem;
                }
              }
              .itemTit {
                flex-shrink: 0;
              }
              .contents {
                .catArea {
                  display: flex;
                  flex-wrap: wrap;
                  column-gap: 1.2rem;
                  row-gap: 1rem;
                  @include media(sm) {
                    gap: 0.4rem;
                  }
                  .cat {
                    padding: 0.5rem 1.4rem;
                    border-radius: 1rem;
                    background-color: #dedede;
                    color: #000;
                    @include media(sm) {
                      @include fzr(10, 20, 700);
                    }
                    &--active {
                      color: #fff;
                      background-color: #c84658;
                    }
                  }
                }
              }
            }
          }
        }
        .btnArea {
          display: flex;
          gap: 1.6rem;
          margin-top: 4rem;
          @include media(sm) {
            margin-top: 2.5rem;
          }
          .c-btnA {
            &::before {
              width: 18.8rem;
              height: 5.8rem;
              background-color: #920303;
            }
            &--dl {
              gap: 0.9rem;
              &::before {
                background-color: #c84658;
              }
            }
          }
        }
        .linkArea {
          margin-top: 2.4rem;
          @include media(sm) {
            margin-top: 2.1rem;
          }
          .link {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 1.2rem;
            .text {
              @include fzr(16, 26, 700);
            }
          }
        }
      }
    }

    &__titArea {
      @include fzr(24, 26, 700);
      margin-bottom: 3.7rem;
      @include media(sm) {
        @include fzr(18, 26, 700);
        margin-bottom: 2.8rem;
      }
    }
    &__infoArea {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 5.5rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid #707070;
      @include media(sm) {
        margin-bottom: 5.2rem;
      }
      .numArea {
        span {
          @include fzr(16, 26, 700);
        }
      }
      .orderArea {
        position: relative;
        select {
          border: 1px solid #aaaaaa;
          border-radius: 1rem;
          background-color: #fff;
          padding: 1rem 4.2rem 1rem 2rem;
          @include fzr(14, 20, 700);
          @include media(sm) {
            padding: 0.8rem 3.8rem 0.8rem 1.6rem;
          }
        }
        .icon {
          position: absolute;
          top: 50%;
          right: 2rem;
          transform: translateY(-50%);
          width: 1rem;
          pointer-events: none;
        }
      }
    }
    &__boxTit {
      margin-top: 9rem;
      margin-bottom: 6.7rem;
      @include fzr(40, 41, 900);
    }
    &-box {
      position: relative;
      z-index: 3;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 3.6rem;
      row-gap: 6rem;
      margin-top: 9rem;
      @include media(sm) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.4rem;
        row-gap: 3rem;
      }
      &__item {
        .c-cardA {
          &__infoArea {
            .btnArea {
              .c-btnA {
                background-color: #c84658;
                gap: 1rem;
                border-radius: 1rem;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
