@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.l-hdr {
  position: fixed;
  top: 3.4rem;
  left: 0;
  right: 0;
  z-index: 99;
  @include media(sm) {
    position: absolute;
  }
  &-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__logoArea {
      .logo {
        img {
          width: 15.3rem;
        }
      }
    }
    &__navArea {
      display: flex;
      align-items: center;
      gap: 4rem;
      @include media(sm) {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        width: 100%;
        padding: 10.6rem 2.5rem 2rem;
        background-color: rgba($color: #fff, $alpha: .9);
      }
      .mainNav {
        display: flex;
        gap: 4rem;
        @include media(sm) {
          flex-direction: column
        }
        .navItem {
          @include fzr(16,23,500);
          @include media(sm) {
            @include fzr(18,23,500);
          }
        }
      }
      .btnArea {
        @include media(sm) {
          margin-top: 4rem;
        }
        .orderBtn {
          position: relative;
          z-index: 2;
          color: #fff;
          width: 16.3rem;
          height: 5.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media(sm) {
            background-color: #920303;
            border-radius: 4.4rem;
            width: 21rem;
            height: 5.5rem;
          }
          &::before {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            content: '';
            background-color: #920303;
            transition: .4s all;
            border-radius: 1rem;
            width: 16.3rem;
            height: 5.8rem;
            transform: skewX(-10deg);
            @include media(sm) {
              display: none;
            }
          }
          &::after {
            position: absolute;
            top: .5rem;
            left: .5rem;
            z-index: -2;
            content: '';
            background-color: #fff;
            border: 1px solid #920303;
            border-radius: 1rem;
            width: 16.3rem;
            height: 5.8rem;
            transform: skewX(-10deg);
            @include media(sm) {
              display: none;
            }
          }
          
        }
      }
    }
    &__ham {
      @include media(sm) {
        background-color: #920303;
        position: fixed;
        z-index: 999;
        right: 1.6rem;
        top: 1.8rem;
        width: 6.6rem;
        height: 6.6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .hamBtn {
          position: relative;
          
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          width: 100%;
          width: 2.8rem;
          height: 2rem;
          .lineArea {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 2.4rem;
            span {
              background-color: #fff;
              border-radius: 2px;
              transition: all 0.4s;
              width: 2.8rem;
              height: 2px;
              &:nth-child(1) {
                
              }
              &:nth-child(2) {
                
              }
              &:nth-child(3) {
                
              }
            }
          }
          .text {
            color: #fff;
            @include fzr(12,17,700);
            letter-spacing: .1em;
            &--close {
              display: none;
            }
          }
          &:hover {
            opacity: 1;
          }
          &.is-active {
            position: relative;
            .text {
              display: none;
              &--close {
                display: block;
              }
            }
            .lineArea {
              span {
                position: absolute;
                top: 1rem;
                background-color: #fff;
                &:nth-child(1) {
                  transform: rotate(45deg);
                  left: 0;
                  
                }
                &:nth-child(2) {
                  width: 0;
                  height: 0;
                }
                &:nth-child(3) {
                  transform: rotate(135deg);
                  right: 0;
                  
                }
              }
            }
          }
        }
      }
    }
  }
}
