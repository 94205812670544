@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;

.p-contact {
  &__form {
    &-item {
      .wpcf7-form-control-wrap {
        display: flex;
      }
      .wpcf7-radio.contact_checkbox,
      .wpcf7-radio.contact_radio {
        @include media(sm) {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
        .wpcf7-list-item {
          margin: 0;
          + .wpcf7-list-item {
            margin-left: 1em;
            @include media(sm) {
              margin-left: 0;
            }
          }
          label {
            position: relative;
            input {
              opacity: 0;
              visibility: hidden;
              position: absolute;

              &:checked {
                + .wpcf7-list-item-label {
                  &::after {
                    display: block;
                  }
                }
              }
            }
            .wpcf7-list-item-label {
              display: flex;
              align-items: center;
              cursor: pointer;
              &::before {
                display: block;
                content: "";
                border-radius: 50%;
                border: 2px solid #000;
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
              }
              &::after {
                position: absolute;
                content: "";
                border-radius: 50%;
                width: 1.2rem;
                height: 1.2rem;
                background-color: #920303;
                left: .4rem;
                top: 50%;
                transform: translateY(-50%);
                display: none;
              }
            }
          }
        }
      }
      .contact_tab_radio {
        border: 1px solid #000;
        .wpcf7-list-item {
          margin: 0;
          + .wpcf7-list-item {
            border-left: 1px solid #000;
          }
          label {
            cursor: pointer;
            position: relative;
            input {
              opacity: 0;
              position: absolute;
              &:checked {
                + .wpcf7-list-item-label {
                  background-color: #920303;
                  color: #fff;
                }
              }
            }
            .wpcf7-list-item-label {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 1rem 3rem;
              width: 20rem;
              @include media(sm) {
                width: 14rem;
              }
            }
          }
        }
      }
      .subTextWrap {
        display: flex;
        align-items: center;
        gap: 3rem;
        @include media(sm) {
          align-items: flex-start;
          flex-direction: column;
        }
        .wpcf7-form-control-wrap {
          flex-shrink: 0;
        }
        .subTextArea {
          display: flex;
          gap: 2rem;
          border: solid 2px #000;
          border-radius: 6px;
          .tit {
            font-size: 1.6rem;
            font-weight: bold;
            border-right: solid 2px #000;
            padding: 0.5rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
          }
          .inputArea {
            input {
              // border: solid 2px #000;
              border-radius: 6px;
              padding: 0.5rem 1rem;
              width: 100%;
              font-weight: bold;
              font-size: 1.4rem;
              line-height: 1.5;
            }
          }
        }
      }
      .subTextArea {
        &--image {
          margin-top: 2rem;
          display: flex;
          gap: 2rem;
          border: solid 2px #000;
          border-radius: 6px;
          .tit {
            font-size: 1.6rem;
            font-weight: bold;
            border-right: solid 2px #000;
            padding: 0.5rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
          }
          .inputArea {
            input {
              // border: solid 2px #000;
              border-radius: 6px;
              padding: 0.5rem 1rem;
              width: 100%;
              font-weight: bold;
              font-size: 1.4rem;
              line-height: 1.5;
            }
          }
        }
        
      }
    }
    &-submit {
      .wpcf7-acceptance {
        .wpcf7-list-item {
          margin-left: 0;
        }
      }
    }
  }
}
