@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
/*
  基本的にa,buttonタグにつける
  中央寄せなどは親要素で設定
*/
.c-btnA {
  position: relative;
  z-index: 2;
  color: #fff;
  width: 17.9rem;
  height: 5.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media(sm) {
    width: 15.3rem;
    height: 4.4rem;
    border-radius: 2.8rem;
    @include fzr(16,23,500);
  }
  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    content: "";
    background-color: #C8552E;
    transition: 0.4s all;
    border-radius: 1rem;
    width: 17.9rem;
    height: 5.8rem;
    transform: skewX(-10deg);
    @include media(sm) {
      width: 15.3rem;
      height: 4.4rem;
    }
  }
  &--blue {
    &::before {
      background-color: #1A5C6F;
    }
    @include media(sm) {
      background-color: #1A5C6F;
    }
  }
  &--red {
    &::before {
      background-color: #920303;
    }
  }
}
