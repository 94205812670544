@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-pager {
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media(sm) {
    }
    span,
    a {
      
      
      transition: all .4s;
      @include fzr(18,20,700);
      
    }
    .page,
    .current {
      border-bottom: 4px solid #fff;
      padding: 0 2.3rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @include fzr(22,26,900);
      font-family:  "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "游ゴシック体",
		YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Meiryo", sans-serif;
      @include media(sm) {
          padding: 0 1.8rem 1rem;
      }
    }
    .current {
      border-color: #920303;
      color: #920303;
    }
    a {
      &.page {
        &:hover {
          border-color: #920303;
          color: #920303;
          opacity: 1;
        }
      }
      
    }
    .previouspostslink,
    .nextpostslink {
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        content: '';
        background: url(../../images/icon_arrow--left.svg) no-repeat;
        background-size: .8rem;
        width: .8rem;
        height: 1.5rem;
      }
    }
    .nextpostslink {
      &::before {
        transform: scale(-1,1) translate(-50%,-50%);
      }
    }
  }
  &-single {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__linkArea {
      margin: 0 31px;
    }
    .nav-previous,
    .nav-next {
      a {
        display: flex;
        align-items: center;
        color: #CECECE;
      }
    }
    .nav-previous {
      a {
        img {
          margin-right: 5px;
        }
      }
    }
    .nav-next {
      a {
        img {
          margin-left: 5px;
        }
      }
    }
  }
}