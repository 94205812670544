@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
.c-titA {
  position: relative;
  &__jp {
    position: relative;
    z-index: 2;
    @include fzr(40,41,900);
    @include media(sm) {
      @include fzr(26,27,900);
    }
    .small {
      font-size: 3.2rem;
      @include media(sm) {
        font-size: 2rem;
      }
    }
  }
  &__en {
    position: absolute;
    top: -3rem;
    left: -15rem;
    @include fzr(120,41,900);
    color: #E6E6E6;
    @include media(sm) {
      left: -4rem;
      bottom: 1.5rem;
      top: auto;
      @include fzr(80,41,900);
      white-space: nowrap;
    }
  }
}
