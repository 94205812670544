@use "../foundation/f-mixin" as *;
@use "../foundation/f-variables" as *;
#search {
  .p-sec01 {
    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      content: "";
      background: url(../../images/search_upper_bg.png) no-repeat;
      background-size: 128rem;
      width: 100%;
      height: 78.5rem;
      @include media(sm) {
        background: url(../../images/search_upper_bg_sp.png) no-repeat;
        background-size: 37.5rem;
        width: 100%;
        height: 29.6rem;
      }
    }
    
    &__contents {
      padding: 16.8rem 0 20.2rem;
      position: relative;
      z-index: 3;
      @include media(sm) {
        padding: 5.6rem 0 16rem;
      }
      &::after {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        background: url(../../images/search_rower_bg.png) no-repeat;
        background-size: 128rem;
        width: 100%;
        height: 16.3rem;
        @include media(sm) {
          background: url(../../images/search_rower_bg_sp.png) no-repeat;
          background-size: 37.5rem;
          width: 37.5;
          height: 9.4rem;
        }
      }
    }

    &__titArea {
      @include fzr(24, 26, 700);
      margin-bottom: 3.7rem;
      @include media(sm) {
        @include fzr(18, 26, 700);
        margin-bottom: 2.8rem;
      }
    }
    &__infoArea {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 5.5rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid #707070;
      @include media(sm) {
        margin-bottom: 5.2rem;
      }
      .numArea {
        span {
          @include fzr(16, 26, 700);
        }
      }
      .orderArea {
        position: relative;
        select {
          border: 1px solid #aaaaaa;
          border-radius: 1rem;
          background-color: #fff;
          padding: 1rem 4.2rem 1rem 2rem;
          @include fzr(14, 20, 700);
          @include media(sm) {
            padding: 0.8rem 3.8rem 0.8rem 1.6rem;
          }
        }
        .icon {
          position: absolute;
          top: 50%;
          right: 2rem;
          transform: translateY(-50%);
          width: 1rem;
          pointer-events: none;
        }
      }
    }

    &-box {
      position: relative;
      z-index: 3;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 3.6rem;
      row-gap: 6rem;
      @include media(sm) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 1.4rem;
        row-gap: 3rem;
      }

      &__wrapRight {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 2.2rem;
        @include media(sm) {
          gap: 1rem;
        }
      }
      &__wrap {
        display: flex;
        gap: 2.8rem;
        @include media(sm) {
          gap: 1rem;
        }
      }
      &__item {
        position: relative;
        z-index: 1;
        .cat {
          position: absolute;
          &--01 {
            left: -2.4rem;
            bottom: 4rem;
            img {
              width: 22.2rem;
              @include media(sm) {
                width: 17.5rem;
              }
            }
          }
          &--02 {
            right: -2rem;
            bottom: -0.4rem;
            @include media(sm) {
              right: -1rem;
            }
            img {
              width: 18.9rem;
              @include media(sm) {
                width: 13.5rem;
              }
            }
          }
          &--03 {
            right: -2rem;
            bottom: -0.4rem;
            @include media(sm) {
              right: -1rem;
            }
            img {
              width: 16.5rem;
              @include media(sm) {
                width: 11.9rem;
              }
            }
          }
          &--04 {
            right: -2rem;
            bottom: -0.4rem;
            @include media(sm) {
              right: -1rem;
            }
            img {
              width: 15.4rem;
              @include media(sm) {
                width: 8.7rem;
              }
            }
          }
          &--05 {
            right: -2rem;
            bottom: -0.4rem;
            @include media(sm) {
              right: -1rem;
            }
            img {
              width: 18.2rem;
              @include media(sm) {
                width: 10.6rem;
              }
            }
          }
          &--06 {
            right: -2rem;
            bottom: -0.4rem;
            @include media(sm) {
              right: -1rem;
            }
            img {
              width: 13.7rem;
              @include media(sm) {
                width: 7.4rem;
              }
            }
          }
        }
      }
    }
    &__pager {
      margin-top: 9.2rem;
    }
  }
}
